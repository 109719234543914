import React, { useState, useEffect } from 'react';

import {
    Stack,
    Alert,
    Snackbar
} from "@mui/material";
import { useAppDispatch, useAppSelector } from '../lib/hook';
import { chatSelector, resetAlert as resetChatAlert } from '../lib/ducks/chatDuck';
import { authSelector, resetAlert as resetAuthAlert } from '../lib/ducks/authDuck';
import { AlertModel } from '../lib/ducks/alertType';
import { SnackbarOrigin } from '@mui/material/Snackbar';


interface State extends SnackbarOrigin {
    open: boolean;
}

const AlertHandler = () => {
    const dispatch = useAppDispatch();
    const chatAlert = useAppSelector(chatSelector).alert;
    const authAlert = useAppSelector(authSelector).alert;
    const [state, setState] = React.useState<SnackbarOrigin>({
        vertical: 'bottom',
        horizontal: 'right',
    });
    const { vertical, horizontal } = state;
    const [activeAlert, setActiveAlert] = useState<AlertModel | null>(null);
    const [alertType, setAlertType] = useState("success")

    useEffect(() => {
        if (chatAlert != null) {
            setAlertType(chatAlert.type);
            setActiveAlert(chatAlert);
            dispatch(resetChatAlert());
        }
        if (authAlert != null) {
            setAlertType(authAlert.type);
            setActiveAlert(authAlert);
            dispatch(resetAuthAlert());
        }

    }, [chatAlert, authAlert])


    const handleClose = () => {
        setActiveAlert(null);
    }

    return (
        <Snackbar
            open={activeAlert != null}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
            key={activeAlert?.key}
        >
            <Alert
                severity={alertType === "success" ? "success" : "error"}
                onClose={handleClose}
                //variant="filled"

                sx={{ width: '100%' }}
            >
                {activeAlert?.message}
            </Alert>
        </Snackbar>

    );
}

export default AlertHandler