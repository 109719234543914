import React, { useState, useEffect } from 'react';

import {
    Container,
    TextField,
    Typography,
    Button,
    Box,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    List,
    Grid
} from "@mui/material";

import PopupChat from '../components/chat/popupChat';
import { useAppSelector, useAppDispatch } from '../lib/hook';
import { chatSelector, createAndFetchChats, postAndFetchChats, abortAndFetchChat, RephraseMessage, rephraseChat, rephraseAndFetchChats } from '../lib/ducks/chatDuck';
import { WelcomeMessage } from '../components/welcomeMessage';
import { authSelector } from '../lib/ducks/authDuck';

interface ComponentProps {
    //selectedId: string | null
}

const ChatView: React.FC<ComponentProps> = ({ }) => {
    const dispatch = useAppDispatch();
    const chatState = useAppSelector(chatSelector)
    const selectedId = useAppSelector(chatSelector).selectedChatId
    const user = useAppSelector(authSelector).user;
    const selectedPipe = useAppSelector(chatSelector).selectedPipe
    const roles = useAppSelector(authSelector).user?.roles
    const selectedRoleId = roles ? roles[selectedPipe] : undefined;

    const sendMessage = (message: string, exclude_ids: string[], sources_tables: string[]) => {
        if (selectedId) {
            dispatch(postAndFetchChats(selectedId, { message, exclude_ids, sources_tables, role_id: selectedRoleId }))
        } else {
            dispatch(createAndFetchChats({ message, exclude_ids, sources_tables, role_id: selectedRoleId }))
        }
    };

    const rephraseMessage = (message: string, sources_tables: string[]) => {
        const body = { message, sources_tables, role_id: selectedRoleId } as RephraseMessage
        if (selectedId) {
            dispatch(rephraseAndFetchChats(selectedId, body));
        } else {
            dispatch(rephraseAndFetchChats("", body));
        }

    }

    const handleAbort = () => {
        if (selectedId) {
            dispatch(abortAndFetchChat(selectedId));
        }
    };

    return (
        <PopupChat isLoading={chatState.messageIsLoading} messages={chatState?.selectedChat} sendMessage={sendMessage} handleAbort={handleAbort} rephraseMessage={rephraseMessage} />
    )

};

export default ChatView;