import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Message, Source } from '../../lib/ducks/chatDuck';
import { Link, Typography, Divider, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ComponentProps {
    message: Message,
    open: boolean,
    handleClose: any
}

export const MessageDialog: React.FC<ComponentProps> = ({ message, open, handleClose }) => {

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} >
                        Vastauksen tiedot
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box></DialogTitle>
            <DialogContent dividers>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    children={message.content}
                //children={"test[^1] [^2]\n text [^3]"}
                />
                {
                    message.sources.map((source: Source, index: number) => (
                        <React.Fragment key={'MessageDialog' + index}>
                            <Divider />
                            <Typography gutterBottom>
                                {source.citation}
                            </Typography>

                            <Link target="_blank" rel="noopener" href={source.href} key={index} sx={{ fontSize: 16, justifyContent: "center" }} style={{ whiteSpace: 'pre-line' }}>
                                {source.href} <br />

                            </Link >
                        </React.Fragment>
                    ))
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined'>Sulje</Button>
            </DialogActions>
        </Dialog>
    );
};