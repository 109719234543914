import { configureStore, combineReducers } from "@reduxjs/toolkit";
import chatReducer from './ducks/chatDuck'
import authReducer from './ducks/authDuck'

function saveTolocalStorage(state: any) {
    try {
        const serializedState = JSON.stringify({ auth: state.auth });
        localStorage.setItem('localStorageState', serializedState);
    } catch (e) {
        console.log("saving storage error:", e);
    }
}

function loadFromLocalstorage() {
    try {
        const serializedState = localStorage.getItem('localStorageState');
        if (serializedState === null) return {};
        const state = JSON.parse(serializedState);
        const newstate = { ...state, auth: { ...state.auth, isLoading: false } };
        return newstate;
    } catch (e) {
        console.log("loading storage error:", e);
        return {};
    }
}


const rootReducer = combineReducers({
    chat: chatReducer,
    auth: authReducer
})

// create our store from our rootReducers and use loadFromLocalStorage
// to overwrite any values that we already have saved
export const store = configureStore({
    reducer: rootReducer,
    preloadedState: loadFromLocalstorage(),
    devTools: process.env.NODE_ENV !== 'production',
});

store.subscribe(() => saveTolocalStorage(store.getState()));

export default store;
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch