
import React, { useState } from "react";
import { Container, Box, Paper, Stack, TextField, Grow, Typography, Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { authSelector, login } from "../lib/ducks/authDuck";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import LoadingButton from '@mui/lab/LoadingButton';
import AlertHandler from "../components/alertHandler";

const themeLight = createTheme({
    palette: {
        mode: 'light',
        bubbleColor: "grey.900",
        boxShade: '#0e0e0e99',
        responseBubbleColor: "grey.900",
        sidePanel: "#171526",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {

                root: {

                    borderRadius: '8px',
                    padding: '12px 24px',
                    textTransform: "none",
                },
                containedPrimary: {
                    backgroundColor: '#3A52D1',
                    //color: '#FFFFFF',
                },
                outlinedPrimary: {
                    color: '#545455',
                    borderColor: '#545455',
                },
                textPrimary: {
                    color: '#545455',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Puts the label into notched position
                    '.MuiInputLabel-outlined:has(+ .MuiOutlinedInput-root > .MuiOutlinedInput-input:-webkit-autofill)': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    },
                    // Creates a hole around the text inside the border
                    '.MuiOutlinedInput-input:-webkit-autofill + .MuiOutlinedInput-notchedOutline > legend': {
                        maxWidth: '100%',
                    },
                },
            },
        },
    }
});

export default function LoginView(props: any) {
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const isLoading = useAppSelector(authSelector).isLoading

    const loginPress = () => {
        const body = {
            email: username,
            password: password
        }
        dispatch(login(body));
    }

    return (
        <ThemeProvider theme={themeLight}>
            <CssBaseline />
            <Box sx={{ display: "flex", flexDirection: 'row', height: '100vh' }}>
                <Box sx={{
                    flex: 1, minWidth: '70%',
                    display: 'flex', justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#CFD4EA'
                }}>
                    <img
                        src="/images/login.gif"
                        height={'auto'}
                        width='100%'
                    />
                </Box>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column', alignItems: 'center',
                    padding: '48px'
                }}>
                    <Box
                        width={'100%'}
                        alignItems={'left'}
                    >
                        <img
                            src="/images/codex_logo_black.png"
                            height={'auto'}
                            width='137px'
                        />

                    </Box>

                    <Stack
                        marginTop={'124px'}
                        gap={'24px'}
                        width={'100%'}
                    >
                        <Typography variant="h6" fontSize={'24px'} >
                            Tervetuloa Codex+ palveluun!
                        </Typography>
                        <Typography variant="h6" fontSize={'15px'}>
                            Kirjaudu sisään jatkaaksesi Codexiin.
                        </Typography>
                        <TextField fullWidth label="Käyttäjätunnus"
                            value={username}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUsername(event.target.value);
                            }}
                            autoFocus={true}
                        />
                        <TextField fullWidth label="Salasana" type="password"
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value);
                            }}
                            autoComplete="current-password" />
                        <LoadingButton loading={isLoading} onClick={loginPress} variant='contained' sx={{ width: '160px' }} >Kirjaudu</LoadingButton>

                    </Stack>
                </Box>
            </Box>
            <AlertHandler />
        </ThemeProvider>
    )
}