import { Box } from "@mui/material"


export const LoadingScreen = () => {

    return (
        <Box
            minWidth={'100vh'}
            minHeight={'100vh'}
            sx={{
                backgroundColor: 'transparent'
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <img
                src={"/images/loading.gif"}
            //height={'auto'}
            //width={'32px'}
            />

        </Box>
    )
}