import { EditNote } from "@mui/icons-material";
import { Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled, Toolbar, Tooltip, Typography } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import React from "react";
import StyledAvatar from "./avatar";


import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import InfoIcon from '@mui/icons-material/Info';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useAppDispatch, useAppSelector } from "../lib/hook";
import { chatSelector, setSelectedPipe } from "../lib/ducks/chatDuck";
import { authSelector, requestLogoutAllAndLogout, setDarkTheme } from "../lib/ducks/authDuck";
import { useNavigate } from "react-router-dom";
import useConfirm from "./dialogs/ConfirmDialog";

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

import CheckIcon from '@mui/icons-material/CheckCircle';



const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, drawerwidth }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - 80px)`,
    ...(open && {
        width: `calc(100% - ${drawerwidth}px)`,
        marginLeft: `${drawerwidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    drawerwidth: number
}

interface ComponentProps {
    drawerWidth: number,
    leftDrawerOpen: boolean | undefined,
    handleNewChat: any,
    handleDrawerOpen: any,
    handleDrawerClose: any,
    handleFeedback: any
}

interface RoleIconProps {
    role: string,
}

const RoleIcon: React.FC<RoleIconProps> = ({ role }) => {

    if (role === "Työ")
        return (<BusinessCenterIcon />)

    if (role === "Rikos")
        return (<LocalPoliceIcon />)

    if (role === "Perintö")
        return (<ReduceCapacityIcon />)

    if (role === "Perhe")
        return (<FamilyRestroomIcon />)


    return <></>
}

export const TopBar: React.FC<ComponentProps> = ({ drawerWidth, leftDrawerOpen, handleNewChat, handleDrawerOpen, handleDrawerClose, handleFeedback }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const [getConfirmation, ConfirmationDialog] = useConfirm();
    const query_quota = useAppSelector(chatSelector).query_quota
    const user = useAppSelector(authSelector).user
    const roles = useAppSelector(authSelector).user?.roles
    const selectedPipe = useAppSelector(chatSelector).selectedPipe
    const darkTheme = useAppSelector(authSelector).darkTheme
    const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null);
    const [anchorElPipe, setAnchorElPipe] = React.useState<null | HTMLElement>(null);


    const pipeMenuOpen = Boolean(anchorElPipe);
    const profileMenuOpen = Boolean(anchorElProfile);
    const handlePipeMenuClose = () => {
        setAnchorElPipe(null);
    };
    const handleProfileMenuClose = () => {
        setAnchorElProfile(null);
    };

    const onLogout = async () => {
        const response = await getConfirmation({ title: 'Haluatko varmasti kirjautua ulos?', text: '', okText: 'Kyllä', cancelText: 'Peruuta', buttonColorWarning: false })
        if (response) {
            dispatch(requestLogoutAllAndLogout())
        }
    }


    return (
        <>
            <ConfirmationDialog />
            <AppBar drawerwidth={drawerWidth} position="fixed" open={leftDrawerOpen} sx={{ height: '64px', borderBottom: 1, borderColor: '#E6E6E9' }} elevation={0}>
                <Toolbar>

                    <IconButton
                        color="inherit"
                        aria-label="new chat"
                        onClick={handleNewChat}
                        edge="start"
                        sx={{ mr: 2, ...(leftDrawerOpen && { display: 'none' }) }}
                    >
                        <EditNote />
                    </IconButton>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            mr: 2,
                            ...(leftDrawerOpen && { display: 'none' })
                        }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                    <QuestionAnswerIcon sx={{
                        ml: 1,
                        mr: 2,
                        //...(!leftDrawerOpen && { display: 'none' }) 
                    }} />
                    <Typography variant="h6" fontWeight={600} noWrap component="div"
                        sx={{ mr: 2 }}
                    >
                        Codex+
                    </Typography>

                    {selectedPipe != "default" &&
                        <>
                            <RoleIcon role={selectedPipe} />
                            <Typography variant="h6" fontWeight={600} noWrap component="div"
                                sx={{ mr: 2, ml: 1 }}
                            >
                                {selectedPipe}
                            </Typography>
                        </>
                    }

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={(event) => { setAnchorElPipe(event.currentTarget); }}
                        edge="start"
                        sx={{
                            //flexGrow: 1 
                            marginRight: 'auto',
                            //ml: 2,
                            transform: 'rotate(270deg)'
                        }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <Tooltip title={"Jäljellä olevat kysymykset"}>
                        <MonetizationOnIcon />
                    </Tooltip>
                    <Tooltip title={"Jäljellä olevat kysymykset"}>
                        <Typography variant="h6" fontWeight={600} fontSize={'18px'} noWrap component="div" paddingLeft={'8px'} >
                            {query_quota}
                        </Typography>
                    </Tooltip>

                    <IconButton sx={{ marginLeft: '32px' }} onClick={(event) => { setAnchorElProfile(event.currentTarget); }}>
                        <StyledAvatar avatarName={user?.avatar_name} size={42} sx={{}} />
                    </IconButton>
                </Toolbar>
            </AppBar >
            <Toolbar sx={{ borderBottom: 1, height: '64px' }} />

            <Menu
                anchorEl={anchorElProfile}
                open={profileMenuOpen}
                onClose={handleProfileMenuClose}
            >
                <MenuItem onClick={() => { navigate('/profile'); handleDrawerClose(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <StyledAvatar avatarName={user?.avatar_name} size={40} sx={{}} />
                    </ListItemIcon>
                    <Box
                        //display={'flex'} flex={1} alignItems={'center'} flexDirection={'column'}
                        marginLeft={2}
                    >
                        <Box>
                            <Typography fontSize={14} >{user?.first_name + ' ' + user?.last_name}</Typography>
                            <Typography fontSize={11}>{user?.email}</Typography>
                        </Box>
                    </Box>
                </MenuItem>
                {/*
                <MenuItem onClick={() => { handleProfileMenuClose(); dispatch(setDarkTheme(!darkTheme)) }}>
                    <ListItemIcon sx={{ paddingLeft: '4px' }}>
                        {darkTheme ? <DarkModeIcon /> : <LightModeIcon />}
                    </ListItemIcon>
                    <ListItemText>Ulkoasu: {darkTheme ? 'Tumma teema' : 'Vaalea teema'}</ListItemText>
                </MenuItem>
                
                <Divider />
                <MenuItem onClick={() => { navigate('/settings'); handleDrawerClose(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText  >Asetukset</ListItemText>
                </MenuItem>
                */}
                <MenuItem onClick={() => { navigate('/info'); handleDrawerClose(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText >Ohje</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { handleFeedback(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <ModeCommentIcon />
                    </ListItemIcon>
                    <ListItemText >Lähetä palautetta</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { onLogout(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText >Kirjaudu ulos</ListItemText>
                </MenuItem>
            </Menu>


            <Menu
                anchorEl={anchorElPipe}
                open={pipeMenuOpen}
                onClose={handlePipeMenuClose}
                PaperProps={{
                    style: {
                        width: 220,
                    },
                }}
            >
                {roles && Object.keys(roles).map((role, index) => {
                    if (role === "default") return;
                    return (
                        <MenuItem key={"menuitem" + index}
                            sx={{ paddingRight: 0 }}
                            onClick={() => { dispatch(setSelectedPipe(role)) }}
                        >
                            <ListItemIcon >
                                <RoleIcon role={role} />
                            </ListItemIcon>
                            <ListItemText >{role}</ListItemText>
                            {selectedPipe === role &&
                                < ListItemIcon sx={{ ml: 'auto' }}>
                                    <CheckIcon color="primary" />
                                </ListItemIcon>
                            }
                        </MenuItem>
                    )
                })
                }
                <MenuItem
                    sx={{ paddingRight: 0 }}
                    disabled={true}
                >
                    <ListItemIcon >
                        <RoleIcon role={"Perhe"} />
                    </ListItemIcon>
                    <ListItemText >Perhe (tulossa)</ListItemText>
                </MenuItem>
                <MenuItem
                    sx={{ paddingRight: 0 }}
                    disabled={true}
                >
                    <ListItemIcon >
                        <RoleIcon role={"Rikos"} />
                    </ListItemIcon>
                    <ListItemText >Rikos (tulossa)</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { dispatch(setSelectedPipe("default")) }}
                    sx={{ paddingRight: 0 }}
                >
                    <ListItemIcon >
                        <QuestionAnswerIcon />
                    </ListItemIcon>
                    <ListItemText >Codex+</ListItemText>
                    {selectedPipe === "default" &&
                        < ListItemIcon sx={{ ml: 'auto' }}>
                            <CheckIcon color="primary" />
                        </ListItemIcon>
                    }
                </MenuItem>
            </Menu >
        </>
    );
}