import React, { useState, useEffect, useRef } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import {
    TextField,
    Card,
    Paper,
    Box,
    Container,
    CircularProgress,
    useTheme,
    ListItemIcon,
    ListItemText,
    Hidden
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { SentMessage, ReceivedMessage } from './bubbles';
import { Message, RephraseMessage, chatSelector, rephraseChat } from '../../lib/ducks/chatDuck';
import { WelcomeMessage } from '../welcomeMessage';
import { useAppSelector, useAppDispatch } from '../../lib/hook';
import { authSelector } from '../../lib/ducks/authDuck';
import { ChatApi } from '../../lib/apiActions';
import { AlertDialog, InputDialog } from '../dialogs/dialogs';
import { text } from 'body-parser';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SourcesTables } from '../../lib/ducks/chatDuck'
import CheckIcon from '@mui/icons-material/Check';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const PopupChat = (props: any) => {
    const { messages, handleAbort } = props
    const [textField, setTextField] = useState("");
    const user = useAppSelector(authSelector).user;
    const selectedChatId = useAppSelector(chatSelector).selectedChatId;
    const theme = useTheme()
    const bottomRef = useRef<HTMLDivElement>(null);
    const lastRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedSources, setSelectedSources] = useState<number[]>([]);

    const menuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [showArrow, setShowArrow] = useState(false);

    const onScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setShowArrow(false);
            //console.log("bottom")
        } else {
            setShowArrow(true);
        }
    };


    const handleSelectedSources = (source: number | null) => {
        if (source === null) {
            setSelectedSources([]);
            return;
        }
        if (selectedSources.includes(source)) {
            setSelectedSources(l => l.filter(item => item !== source))
        } else {
            setSelectedSources([...selectedSources, source]);
        }
    }

    const scrollToBottom = () => {
        try {
            bottomRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        } catch (ex) {

        }
    };

    const handleRephrase = async (message: string, save_message: boolean) => {
        console.log("handleRephrase", message)
        if (props.isLoading || message.trim() === "") {
            setTextField("");
            return;
        }
        props.rephraseMessage(message, selectedSources);

        setTextField("");
    }


    const handleSend = async (text: string, deniedSources: string[]) => {
        console.log("handleSend", text)
        if (props.isLoading) {
            return;
        }
        props.sendMessage(text, deniedSources, selectedSources)
    }

    useEffect(() => {
        if (props.messages) {
            scrollToBottom();
        }
    }, [props.messages, lastRef.current?.lastElementChild]);

    useEffect(() => {
        console.log("useeffect selectedChatId", selectedChatId)
    }, [selectedChatId])
    //console.log("messages", props.messages)

    return (
        <>

            {selectedChatId || messages?.length > 0 ?
                <Box
                    onScroll={onScroll}
                    sx={{
                        overflow: 'auto', overflowY: 'auto', display: 'flex', justifyContent: 'center', flex: 1
                    }} >
                    <Box ref={lastRef}
                        sx={{
                            maxWidth: '880px', paddingTop: 5, width: '100%', height: '100%'
                        }} >
                        {messages?.length === 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center',
                                    minHeight: "80vh"
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        }

                        {messages?.map((message: Message, index: any) => {
                            if (message.role === 1) {
                                return (
                                    <SentMessage
                                        key={"message" + index}
                                        text={message.content}
                                    />
                                )
                            }
                            if (message.role === 2) {
                                return (
                                    <ReceivedMessage
                                        key={"message" + index}
                                        message={message}
                                        sendMessage={handleSend}
                                        handleAbort={handleAbort}
                                        rephraseMessage={handleRephrase}
                                        lastMessage={messages.length == index + 1}
                                    />
                                )
                            }
                        })}
                        <Box ref={bottomRef} sx={{ minHeight: '105px', width: '100%', flexGrow: 1 }} onScroll={onScroll} />
                    </Box>
                </Box >

                :
                <WelcomeMessage userName={user?.email} />
            }


            <Box
                sx={{
                    marginLeft: '-8px',
                    marginBottom: '32px',
                    position: 'fixed',
                    bottom: 0,
                    //minHeight: '100px',
                    maxWidth: '880px',
                    width: { xs: 'calc(95% - 80px)', sm: 'calc(95% - 450px)' },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    //backgroundColor: "grey.800",
                    backgroundColor: 'transparent'
                }}
            >
                <Card
                    sx={{
                        //margin: 1,
                        //p: 3,
                        padding: 0,
                        //padding: "8px 12px 8px 12px",
                        borderRadius: "8px",
                        width: '100%',
                        maxWidth: '880px',
                        minHeight: '100%',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        //backgroundColor: "grey.800",
                        //backgroundColor: '#0e0e0e99',
                        backgroundColor: theme.palette.boxShade,
                        backdropFilter: 'blur(40px)'
                    }}
                >
                    <TextField
                        helperText={selectedSources.length > 1 ? "Useat lähdevalinnat saattavat hidastaa vastausten saantia" : ""}
                        sx={{
                            width: '80%'
                        }}
                        id="standard-basic"
                        variant="standard"
                        multiline
                        placeholder="Kirjoita viesti"
                        onChange={(event) => setTextField(event.target.value)}
                        value={textField}
                        disabled={props.isLoading}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter' && !ev.shiftKey) {
                                // Do code here
                                ev.preventDefault();
                                handleRephrase(textField, true);
                            }
                        }}
                        InputProps={{
                            disableUnderline: true, // <== added this
                        }}
                    />
                    <Box>
                        <LoadingButton
                            onClick={() => handleRephrase(textField, true)}
                            color="primary"
                            loading={props.isLoading}
                            endIcon={<SendIcon color='primary' />}
                        />
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={menuOpen ? 'long-menu' : undefined}
                            aria-expanded={menuOpen ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleSelectedSources(null)}>
                                {selectedSources.length === 0 &&
                                    <ListItemIcon>
                                        <CheckIcon fontSize="small" />
                                    </ListItemIcon>
                                }
                                <ListItemText>Oletuslähde</ListItemText>
                            </MenuItem>
                            {SourcesTables.map((source, index) => (
                                <MenuItem key={"menu" + index} onClick={() => handleSelectedSources(index)}>
                                    {selectedSources.includes(index) &&
                                        <ListItemIcon>
                                            <CheckIcon fontSize="small" />
                                        </ListItemIcon>
                                    }
                                    <ListItemText>{source.displayName}</ListItemText>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Card>
                {showArrow &&
                    <IconButton sx={{ marginLeft: "calc(100% + 60px)", position: 'absolute' }} onClick={scrollToBottom}>
                        <ArrowDownwardIcon />
                    </IconButton>
                }
            </Box>
        </>
    );
}



export default PopupChat;