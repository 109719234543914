import React, { useState, useEffect } from 'react';

import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ComponentProps {
}

const InfoView: React.FC<ComponentProps> = () => {


    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={'column'}
        >
            <Box>
                <Typography variant="h4" gutterBottom sx={{ marginTop: 5 }}>
                    FAQ
                </Typography>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Miten voin pyytää uudet käyttäjätunnukset?
                    </AccordionSummary>
                    <AccordionDetails>
                        Jos tarvitset uudet käyttäjätunnukset yrityksellenne, olethan yhteydessä tukipalveluumme sähköpostitse osoitteessa tuki@sinai.fi.
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        Voinko käyttää Codex+ -sovellusta muilla kielillä kuin suomeksi?
                    </AccordionSummary>
                    <AccordionDetails>
                        Tällä hetkellä Codex+ -sovellus tukee ainoastaan suomen kieltä. Pyydämme muotoilemaan kaikki kysymykset suomeksi.
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        Käytetäänkö keskustelujani sovelluksen koulutusaineistona?
                    </AccordionSummary>
                    <AccordionDetails>
                        Ei, Codex+ -sovelluksen kautta esitetyt kysymykset eivät päädy osaksi koulutusaineistoa.
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        Voinko luottaa, että tekoäly antaa minulle oikeaa tietoa?
                    </AccordionSummary>
                    <AccordionDetails>
                        Kuten muidenkin tekoälypohjaisten sovellusten (esim. ChatGPT) osalta, myös Codex+ saattaa joskus antaa virheellisiä vastauksia.
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        Tietosuojaseloste
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            🛡️ Datasi on turvassa ja yksityistä.
                        </Typography>
                        <Link href='https://www.codexplus.fi/tietosuoja' target='_blank'>https://www.codexplus.fi/tietosuoja</Link>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    )

};

export default InfoView;
