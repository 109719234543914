import React from 'react';
import {
    Container,
    Box,
    Typography,
    Card,
    Button,
    CardContent
} from '@mui/material'
import { authSelector } from '../lib/ducks/authDuck';
import { useAppSelector } from '../lib/hook';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileUploadIcon from '@mui/icons-material/FileUpload';
interface ComponentProps {
    userName: string | undefined;
}

interface CardProps {
    header: string;
    text: string;
    icon: any;
    buttonIcon?: any;
    buttonText: string;
    buttonDisabled: boolean;
}

const StyledCard: React.FC<CardProps> = (props) => {

    return (
        <Box
            sx={{
                width: '320px',
                height: '236px',
                padding: '24px 32px 24px 32px',
                margin: '16px',
                backgroundColor: '#F8F8F8',
                textAlign: 'center',
                border: '1px',
                borderColor: '#E6E6E9',
                borderRadius: '8px',
                background: '#F8F8F8',
                borderStyle: 'solid',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'

            }}
        >
            {props.icon}
            <Typography variant="h3" component="div" fontWeight={'bold'} fontSize={'18px'} marginBottom={'12px'} marginTop={'12px'} lineHeight={'19px'}>
                {props.header}
            </Typography>
            <Typography variant="body2" fontSize={'16px'} lineHeight={'19px'} >
                {props.text}
            </Typography>
            <Button
                disabled={props.buttonDisabled}
                variant="contained"
                sx={{ marginTop: 'auto', color: '#FFFFFF', backgroundColor: '#5870F0' }}
                endIcon={props.buttonIcon}
            >
                {props.buttonText}
            </Button>
        </Box>
    );
}

export const WelcomeMessage: React.FC<ComponentProps> = ({ userName }) => {
    const darkTheme = useAppSelector(authSelector).darkTheme
    const firstName = useAppSelector(authSelector).user?.first_name
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={'column'}
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={'column'}
                maxWidth={'640px'}
            >
                <img
                    src={darkTheme ? "/images/codex_logo_full.webp" : "/images/codex_logo_black.png"}
                    height={'auto'}
                    width={'200px'}

                />

                <Typography fontSize={'18px'} fontWeight={'bold'} variant="h6" sx={{ marginTop: '24px', marginBottom: '0px' }} align='center'>
                    Onneksi olkoon, olet saanut Codexin käsiisi!
                </Typography>
                <Typography fontSize={'18px'} variant="h5" sx={{ marginTop: '0px' }} align='center'>
                    Tekoälyllä buustattua juristia vastaan on vaikea kilpailla perinteisillä menetelmillä.
                </Typography>
                <Typography variant="h6" fontSize={'16px'} fontWeight={'bold'} sx={{ marginTop: '24px' }} gutterBottom>
                    Hei {firstName}, mitä haluaisit tehdä tänään?
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    p: 1,
                    m: 1,
                    marginTop: '10px'
                }}
                width={'100%'}
                maxWidth={'1024px'}

            >

                <StyledCard
                    header="Tutkimus"
                    text="Hanki vastauksia laeista, oikeustapauksista, säädöksistä jne."
                    icon={<DescriptionIcon style={{ color: "#5870F0" }} />}
                    buttonText='Aloita tutkimus'
                    //buttonIcon={<ArrowForwardIcon />}
                    buttonDisabled={true}
                />
                <StyledCard
                    header="Yhteenveto"
                    text="Tiivistä tärkeä tieto mistä tahansa asiakirjasta."
                    icon={<DocumentScannerIcon style={{ color: "#5870F0" }} />}
                    buttonText='Tulossa'
                    //buttonIcon={<FileUploadIcon />}
                    buttonDisabled={true}
                />
                <StyledCard
                    header="Avainsanahaku"
                    text="Tämä toiminnallisuus on parhaillaan työn alla."
                    icon={<FindInPageIcon style={{ color: "#5870F0" }} />}
                    buttonText='Tulossa'
                    //buttonIcon={<FindInPageIcon />}

                    buttonDisabled={true}
                />


            </Box>
        </Box>
    );
};